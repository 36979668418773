<template>
  <a-card class="top-post" :widget="true" :title="`Top post by ${mode === 'mention' ? 'mentioned' : mode} `">
    <div v-if="loading" class="loading-wrapper">
      <a-skeleton :loading="loading" active />
    </div>

    <div v-if="!loading && mode === 'mention'" class="error-wrapper">
      <NoDataAspect />
    </div>

    <div v-else-if="!loading && onlyTop3Data.length === 0" class="error-wrapper">
      <NoDataFound />
    </div>

    <div v-else-if="!loading && onlyTop3Data.length > 0" class="list-wrapper">
      <div v-for="(item, index) in onlyTop3Data" :key="item.user.username" class="list-item">
        <MessageItem
          :item="item"
          :order-no="index + 1"
          :show-category="true"
          :has-thumb="true"
          :showLongText="true"
          :parentName="'toppost'"
          @click="drilldown(item)"
        />
      </div>
    </div>
    <SeeMoreDialog />
  </a-card>
</template>

<script>
import { ref, reactive, computed, toRefs, watch, provide } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';
import NoDataAspect from '@/components/Error/NoDataAspect.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import MessageItem from '@/views/Overview/MessageItem.vue';
import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';

export default {
  name: 'TopPostByMode',
  components: {
    NoDataAspect,
    NoDataFound,
    MessageItem,
    SeeMoreDialog,
  },
  props: {
    mode: String,
    filterResult: Object,
  },
  setup(props) {
    const store = useStore();
    const { filterResult, mode } = toRefs(props);
    const loading = ref(false);
    const error = ref(false);
    const topPostList = reactive({
      data: [],
      subCategoryName: {},
    });
    // const subCategoryName = ref({});

    const onlyTop3Data = ref([]);

    const subCategoryName = computed(() => {
      if (topPostList.subCategoryName) {
        return topPostList.subCategoryName;
      }
      return [];
    });

    const getTopPost = async (fv, sort) => {
      error.value = false;
      const result = await api
        .getSearchData({
          ...fv,
          sort: {
            field: sort,
            direction: 'desc',
          },
          highlight: {
            enable: true,
          },
        })
        .catch(() => {
          error.value = true;
        });
      if (result && result.message && result.message.data) {
        const highlightResult = await store.dispatch('config/highlightKeywords', {
          messageList: result.message.data,
          truncateAt: 0,
        });
        topPostList.data = highlightResult;
        topPostList.subCategoryName = result.message.subCategoryName;
        if (topPostList.data) {
          onlyTop3Data.value = topPostList.data.slice(0, 3);
        } else {
          onlyTop3Data.value = [];
        }
      }
      loading.value = false;
    };

    const drilldown = (item) => {
      store.dispatch('seemore/show', item);
    };

    const init = () => {
      loading.value = true;
      const fv = filterResult.value;
      let filter = '';
      topPostList.data = [];
      topPostList.subCategoryName = {};
      onlyTop3Data.value = [];
      if (mode.value && mode.value !== 'mention') {
        if (mode.value === 'engagement') {
          filter = 'engagement_score';
        } else if (mode.value === 'view') {
          filter = 'view_count';
        }
        getTopPost(fv, filter);
      }
    };

    watch(
      () => filterResult.value,
      async () => {
        await init();
      }
    );

    watch(
      () => mode.value,
      async () => {
        await init();
      }
    );

    provide('onEditMessage', (e) => {
      helper.saveLocalMessage(e, topPostList.data);
    });
    provide('showMode', '');

    provide('subCategoryName', subCategoryName);

    return {
      loading,
      error,
      onlyTop3Data,
      subCategoryName,
      drilldown,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-post {
  .ant-card-head-title {
    text-transform: capitalize;
  }
  .ant-card-body {
    overflow: auto;
  }
  .error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 920px;
    // height: 700px;
  }
  .view-all {
    font-size: 12px;
    color: #3371ff !important;
    cursor: pointer;
  }
  .list-wrapper {
    margin: 0;
    padding: 0;
  }

  .loading-wrapper {
    height: 920px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 20px;
  }
  .list-item {
    margin-bottom: 10px;
    // border: 1px solid #f0f2f5;
    box-shadow: 0 5px 20px rgb(172 172 172 / 23%);
    border-radius: 5px;
  }
  @media only screen and (max-width: 768px) {
    .ant-card-head-wrapper {
      flex-flow: unset;
    }
  }
}
</style>
