<template>
  <div class="campaign-page">
    <CustomPageHeader
      :title="campaignName"
      :isCampaign="true"
      :hasSubtitle="false"
      :hasExport="hasExport"
      :disabledExport="isGenerating"
      :checkActiveKey="checkActiveKey"
      @toggleExport="toggleExport"
    ></CustomPageHeader>
    <div class="page-content" @scroll="onScroll">
      <div class="page-tab-column">
        <MenuTab :tab-lists="tabMenuList" :tab-key="activeKey" @setActiveKey="setActiveKey"></MenuTab>
        <div v-if="isMobileScreen" class="mobile-title">
          <h5 v-if="activeKey === '1'" class="page-title">Performance</h5>
          <h5 v-else-if="activeKey === '2'" class="page-title">Channel</h5>
          <h5 v-else-if="activeKey === '3'" class="page-title">Trending</h5>
        </div>
        <div v-if="activeKey !== '2'" class="filter-div">
          <div v-if="!isMobileScreen" class="social-movement-filter-mode">
            <a-radio-group v-model:value="selectedDataAspect" button-style="solid" size="default">
              <a-radio-button v-for="mode in modeOptions" :key="mode.value" :value="mode.value" style="height: 34px">
                {{ mode.label }}
              </a-radio-button>
            </a-radio-group>
          </div>
          <div v-else class="mobile-social-movement-filter-mode">
            <a-dropdown placement="bottomRight" :getPopupContainer="(trigger) => trigger.parentNode">
              <img :src="require('@/assets/images/icon/icon-sliders.svg')" />
              <template #overlay>
                <a-menu id="mode-menu-mobile" @click="handleModeClick">
                  <a-menu-item-group title="Measurement:">
                    <a-menu-item
                      v-for="mode in modeOptions"
                      :key="mode.value"
                      :style="selectedDataAspect === mode.value ? selectedModeStyle : unSelectedModeStyle"
                    >
                      {{ mode.label }}
                    </a-menu-item>
                  </a-menu-item-group>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
      </div>
      <CampaignPerformance
        v-if="activeKey === '1'"
        :campaign="campaign"
        :mode="selectedDataAspect"
        :filterResult="filterResult"
      />
      <CampaignChannel v-if="activeKey === '2'" :filterResult="filterResult" />
      <CampaignTrending
        v-if="activeKey === '3'"
        :campaign="campaign"
        :mode="selectedDataAspect"
        :filterResult="filterResult"
      />
    </div>
    <ExportCard
      v-if="isOpenExportCard"
      :isGenerating="isGenerating"
      :exportData="exportData"
      @toggleDownload="getExportStatus"
      @closeExportCard="closeExportCard"
    ></ExportCard>
    <SeeMoreDialog />
  </div>
</template>

<script>
import { ref, computed, onBeforeUnmount, onUnmounted, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import api from '@/services/api';
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';
import ExportCard from '@/views/Settings/ExportCard.vue';
import CampaignPerformance from './Performance/CampaignPerformance.vue';
import CampaignChannel from './Channel/CampaignChannel.vue';
import CampaignTrending from './Trending/CampaignTrending.vue';
import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
import MenuTab from '../../components/Tab/MenuTab.vue';

export default {
  name: 'Campaign',
  components: {
    CustomPageHeader,
    ExportCard,
    CampaignPerformance,
    CampaignChannel,
    CampaignTrending,
    SeeMoreDialog,
    MenuTab,
  },
  setup() {
    const store = useStore();
    const route = computed(() => useRoute());
    const router = useRouter();

    const activeKey = ref('1');
    let mode = ref('engagement'); //engagement,mention,view
    let selectedDataAspect = ref('engagement');
    let activeInterval = ref();

    const tabMenuList = [
      {
        label: 'Performance',
        value: '1',
      },
      {
        label: 'Channel',
        value: '2',
      },
      {
        label: 'Trending',
        value: '3',
      },
    ];

    const modeOptions = [
      { label: 'Engagement', value: 'engagement' },
      { label: 'Mentioned', value: 'mention' },
      { label: 'View', value: 'view' },
    ];

    const userInfo = computed(() => store.getters['account/userInfo']);
    const filterCriteria = computed(() => store.getters['filter/filterCriteria']);
    const sourceNameList = computed(() => store.getters['account/sourceNameList']);
    const campaignLevel = computed(() => store.state.account.campaignMenuData.categoryLevel);
    // const category = computed(() => store.getters['config/categoryData']);
    const campaignName = computed(() =>
      decodeURIComponent(route.value.params.id) ? decodeURIComponent(route.value.params.id).toLowerCase() : ''
    );
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    let filterResult = ref();
    const campaign = ref({
      name: campaignName.value,
      level: campaignLevel.value,
    });

    const setActiveKey = (value) => {
      console.log('activeKey: ' + value);
      activeKey.value = value;
    };

    const handleModeClick = async (e) => {
      selectedDataAspect.value = e.key;
    };

    // Export
    let isOpenExportCard = ref(false);
    const toggleExport = (fileType) => {
      exportFile(fileType);
      isOpenExportCard.value = true;
    };

    const closeExportCard = () => {
      isOpenExportCard.value = false;
    };

    let generating = ref(false);

    const exportFile = async (fileType) => {
      generating.value = true;

      if (filterResult.value && filterResult.value.source && filterResult.value.source.length <= 0) {
        filterResult.value.source = [...sourceNameList.value];
      }
      if (fileType.version === 'report3.2') {
        await api.newExport32(filterResult.value, fileType.type, null).catch(() => {});
      } else {
        await api.newExport(filterResult.value, fileType.type, null).catch(() => {});
      }

      getExportStatus();
      activeInterval.value = setInterval(() => {
        getExportStatus();
      }, 5000);
    };

    // export card mini
    const userName = computed(() => store.getters['account/userName']);
    let exportHistory = ref([]);

    const getExportStatus = async () => {
      const result = await api.getExportStatus(userName.value, 1).catch(() => {});
      if (result && result.message) {
        const { records } = result.message;
        exportHistory.value = records;

        const loading = exportHistory.value.some(
          (ele) => ele.exported_status === 'new' || ele.exported_status === 'generating'
        );

        if (!loading) {
          generating.value = false;
          if (activeInterval.value) {
            clearInterval(activeInterval.value);
          }
        } else {
          generating.value = true;
        }
      }
    };

    const exportData = computed(() => {
      return exportHistory.value;
    });

    // if file is downloading > disable button
    const isGenerating = computed(() => {
      return generating.value;
    });

    const checkActiveKey = computed(() => {
      if (activeKey.value === '2') {
        return false;
      } else {
        return true;
      }
    });

    const hasExport = computed(() => {
      if (userInfo.value && userInfo.value.id === '61e53d2e08f1d127489a051d') {
        return false;
      }
      return true;
    });

    const onScroll = () => {
      const scrollElement = document.getElementsByClassName('ant-popover');

      if (scrollElement) {
        for (let ele of scrollElement) {
          ele.style.display = 'none';
        }
      }
    };

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    const init = async (fv) => {
      await store.dispatch('account/getCampaignMenu');
      const campaignCat = [];
      let obj;
      if (campaign.value) {
        if (campaign.value.level === 0) {
          const categoryList = [];
          categoryList.push(campaign.value.name);
          filterResult.value = {
            ...fv,
            category: categoryList,
          };
        } else {
          for (let sub of fv.subCategory) {
            if (parseInt(sub.level) === campaign.value.level) {
              obj = {
                level: sub.level,
                category: [],
              };
              obj.category.push(campaign.value.name);
              campaignCat.push(obj);
            } else {
              campaignCat.push(sub);
            }
          }
          filterResult.value = {
            ...fv,
            subCategory: campaignCat,
          };
        }
      }
    };

    onBeforeUnmount(() => {
      if (activeInterval.value) {
        clearInterval(activeInterval.value);
      }
      store.dispatch('seemore/close');
    });

    onUnmounted(() => {
      clearInterval(activeInterval.value);
    });

    watch(activeKey, () => {
      init(filterCriteria.value);
    });

    watch(
      () => selectedDataAspect.value,
      (previous) => {
        mode.value = previous;
      }
    );

    watch(
      () => filterCriteria.value,
      () => {
        init(filterCriteria.value);
      }
    );

    watch(
      () => campaignName.value,
      () => {
        if (campaignName.value) {
          campaign.value.name = campaignName.value;
          init(filterCriteria.value);
        } else {
          router.push(route.value.fullPath);
        }
      }
    );

    watch(
      () => campaignLevel.value,
      () => {
        campaign.value.level = campaignLevel.value;
      }
    );

    watch(
      () => activeKey.value,
      () => {
        store.dispatch('filter/toggleSource', checkActiveKey.value);
      }
    );

    onMounted(() => {
      init(filterCriteria.value);
    });

    return {
      toggleExport,
      isOpenExportCard,
      closeExportCard,
      isGenerating,
      getExportStatus,
      exportData,
      hasExport,
      onScroll,
      activeKey,
      isMobileScreen,
      mode,
      selectedDataAspect,
      modeOptions,
      handleModeClick,
      selectedModeStyle,
      unSelectedModeStyle,
      campaign,
      checkActiveKey,
      campaignName,
      filterResult,
      tabMenuList,
      setActiveKey,
    };
  },
};
</script>

<style lang="scss" scoped>
.campaign-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .page-content {
    padding: 8px 30px;
    overflow: auto;
    width: 100%;

    .page-tab-column {
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 8px;
      padding-bottom: 35px;
      @media (max-width: 575px) {
        padding-bottom: 20px;
      }
      .mobile-title {
        margin-top: 23px;
        .page-title {
          font-size: 18px;
          margin-bottom: 0;
        }
      }

      .filter-div {
        margin-top: -40px;
      }

      .social-movement-filter-mode {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .social-movement-data-aspect {
          margin-right: 14px;
          font-size: 14px;
          color: #5a5f7d;
        }
      }

      @media screen and (max-width: 767px) {
        .social-movement-title {
          text-align: center;
          margin-bottom: 12px;
        }
        .social-movement-filter-mode {
          justify-content: center;
          text-align: center;
        }

        .filter-div {
          margin-top: unset;
        }
      }
      @media screen and (max-width: 427px) {
        .filter-div {
          margin-left: auto;
        }
      }
    }
  }
}
</style>
